import React from 'react'
import { PageLoader } from '@components/Loader/PageLoader'
import { withUser } from '@lib/firebase/withUser'
import { AuthAction } from '@lib/firebase/AuthAction'
import BasePage from '../components/Layouts/BasePage'

const Index = () => <BasePage title="404" />
export default withUser({
  whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  LoaderComponent: PageLoader,
})(Index)
